import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 98",
  week: "Semana 14",
  day: "19",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-19",
  path: "/cronologia/semana-14#dia-19-jun/",
};

const Day98 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 1.520 casos mediante pruebas PCR. En ese mismo
          periodo, 97 personas han requerido hospitalización (6,4% de los
          diagnósticos), de los que ocho han sido ingresos en la UCI, y se han
          producido 40 fallecimientos.
        </ModText>
        <ModText>
          Se detectan los primeros rebrotes de coronavirus. Ayer, Pekin subió el
          estado de alerta a nivel II. Hoy, Italia y Alemania detectan fuentes
          de rebrotes que se monitorizan con atención. En España, se tienen
          detectados nueve brotes activos que se mantienen bajo control.
        </ModText>
        <ModText>
          Por otro lado, el fuerte rebrote detectado en Turquía hace una semana
          parece estar aplacándose.
        </ModText>
        <ModText>
          Desde hoy, Cantabria, Cataluña, Galicia y País Vasco están en fase de
          Nueva Normalidad. Puedes consultar el mapa de transición y la
          situación de cada territorio en{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/covid-19/Paginas/mapa-fases-desescalada.aspx">
            este enlace
          </InlineLink>
          .
        </ModText>
        <ModText>
          El Ministerio de Sanidad ha actualizado el{" "}
          <InlineLink link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/20200619Proteccion_Trabajadores_SARS-CoV-2.pdf">
            Procedimiento de actuación
          </InlineLink>{" "}
          para los servicios de prevención de riesgos laborales frente a la
          exposición al SARS-CoV-2, en coordinación con las comunidades
          autónomas. En concreto, actualiza las medidas relativas a:
        </ModText>
        <ModDotList>
          Distancia interpersonal en los lugares de trabajo, estableciéndola en
          "al menos 1,5 metros".
        </ModDotList>
        <ModDotList>
          A todo caso sospechoso de infección por el SARS-CoV-2 se le realizará
          una PCR en las primeras 24 horas. Estos se mantendrán en aislamiento a
          la espera del resultado.
        </ModDotList>
        <ModDotList>
          El aislamiento se mantendrá hasta transcurridos tres días desde la
          resolución de la fiebre y del cuadro clínico, con un mínimo de 10 días
          desde el inicio de los síntomas.
        </ModDotList>
        <ModDotList>
          Los profesionales del servicio sanitario del servicio de prevención de
          riesgos laborales serán los encargados de identificar y seguir los
          contactos estrechos en el ámbito laboral.
        </ModDotList>
        <ModDotList>
          Para la búsqueda de contactos estrechos, el periodo a considerar será
          desde 2 días antes del inicio de síntomas.
        </ModDotList>
        <ModDotList>
          Cualquier persona que cumpla la definición de contacto estrecho de un
          caso confirmado deberá ser informada.
        </ModDotList>
        <ModText>
          También se ha publicado en el Boletín Oficial del Estado el{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/06/20/pdfs/BOE-A-2020-6474.pdf">
            listado de medicamentos que son considerados esenciales
          </InlineLink>{" "}
          para la gestión de la crisis sanitaria ocasionada por el COVID-19. A
          partir de ahora, se deberá comunicar semanalmente a la Agencia
          Española de Medicamentos y Productos Sanitarios envases vendidos,
          stock y previsiones.
        </ModText>
        <ModText>
          A escala internacional, España se ha incorporado al equipo negociador
          del Acuerdo de Compra Anticipada de Vacunas frente a la Covid-19 de la
          Unión Europea, junto a con Alemania, Francia, Holanda, Suecia, Polonia
          e Italia.
        </ModText>
        <ModImage src="/images/svg/negociacion-alianza.svg" alt="negociacion" />

        <ModText>
          Los 27 países de la UE se reunieron ayer para iniciar la alianza que
          pretende facilitar el desarrollo y acceso a las dosis necesarias de
          vacunas seguras y eficaces en el menor tiempo posible.
        </ModText>
        <ModText>
          Por otro parte, el Servicio de Sanidad Exterior se reforzará con
          personal de apoyo a partir del próximo domingo, 21 de junio, cuando se
          abran las fronteras interiores con la Unión Europea y el Espacio
          Schengen. Las fronteras con terceros países seguirán restringidas
          hasta el 1 de julio.
        </ModText>
        <ModText>
          Asimismo, las personas que lleguen a España se someterán a tres
          controles primarios. Por una parte, deberán rellenar el formulario de
          localización, la conocida como Passenger Location Card (PLC), que ya
          se usa en estos momentos. Esta incluye datos de localización de la
          persona así como información sobre si ha pasado el Covid-19 y en qué
          circunstancias. También se seguirá realizando el control de
          temperatura. Y finalmente, se efectuará un control visual.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day98;
